import {Stage} from "src/constants/common";
export const STAGE_NAME = '{{Stage}}';

//Sets the right stage based CDN URL for FAB to be consumed via Module Federation.
// @ts-ignore
if (STAGE_NAME.toLowerCase() === Stage.prod) {
    (window as any).FAB_URL = 'https://atoz-livehelp-app.amazon.work';
    (window as any).MLE_URL = 'https://atoz-mle-app.amazon.work';
    (window as any).WORKFLOW_URL = "https://atoz-khonsu-workflow-rendering-app.amazon.work";
    (window as any).MYHR_AI_ASSISTANT_URL = "https://myhr-assistant-ui.amazon.work";
} else {
    (window as any).FAB_URL = 'https://atoz-livehelp-app.integ.amazon.work';
    (window as any).MLE_URL = 'https://atoz-mle-app.integ.amazon.work';
    (window as any).WORKFLOW_URL = "https://atoz-khonsu-workflow-rendering.integ.amazon.work";
    (window as any).MYHR_AI_ASSISTANT_URL = "https://myhr-assistant-ui.integ.amazon.work";
}

import("./bootstrap");

export {};
