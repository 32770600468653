export const CommonStringsConstants = {
  USER_SPECIFIC_INTENTS_RESPONSE_LANDING_PAGE:
    "userSpecificIntentsResponseLandingPage",
  USER_PREFERRED_LANGUAGE: "userPreferredLanguage",
  ELIGIBLE_CONTEXTS_DATA: "eligibleContextsData",
  USER_SPECIFIC_EASE_TILES_LANDING_PAGE: "userSpecificEaseEventsTilesLandingPage"
};

export enum Stage {
  test = "test",
  alpha = "alpha",
  beta = "beta",
  prod = "prod",
}

export enum webPack {
  beta = "https://atoz-feed-app.integ.amazon.work/",
  prod = "https://atoz-feed-app.amazon.work/"
}

export const stageApiUrl: Record<Stage, string> = {
  test: "https://atoz-api.integ.amazon.com/router", // STAGE resolves to test in local environment
  alpha: "https://atoz-api.integ.amazon.com/router",
  beta: "https://atoz-api.integ.amazon.com/router",
  prod: "https://atoz-api.amazon.work/router",
};

export const clientId = "AtoZMyHRApp";
export const ATOZ_MYHR_APP_ROOT_ID = 'atoz-my-hr-app-root';
export const DIRECTION_ATTRIBUTE = 'dir';
export const LEFT_TO_RIGHT = 'ltr';
export const RIGHT_TO_LEFT = 'rtl';
export const ARABIC_LANG = "ar-SA";
export const ENGLISH_LANG = "en-US";
export const DATA_LOCALE = "data-locale";
export const LANG = "lang";
export const requestId = "x-atoz-client-request-id";
export const ATOZ_MYHR2_LOGO = "MyHrLogo.png";
export const LOGIN = "login";
export const PREFERRED_NAME = "preferredName";
export const LEGAL_NAME = "legalName";
export const ATOZ_MYHR2_LOGO_ALT_TEXT = "My HR | Contact Us (Beta)";

export const UTM_SOURCE = "utm_source";

export const COUNT = "Count";

export const FAILURE_IMAGE = "FailurePageImage.svg";

export const ATOZ_MYHR_APP_DATASET_PREFIX = "atoz_my_hr_app";

export const LANDING_PAGE_UI_V2_POPSTAR_FEATURE_NAME = "myhr_ux_v2";

export const T_AND_I_LEVEL_UI_V2_POPSTAR_FEATURE_NAME = "myhr_ti_ux_v2";

export const MYHR_LITE_LOGOUT_FEATURE_NAME = "myhr_lite_logout";

export const MLE_FPP_FEATURE_GATE_NAME = "my_hr_mle_experience";

export const MYHR_DEMOMICROFRONTEND_MODULE = "MyHR:MyHRDemoMicrofrontend";

export const OLD_LANDING_PAGE_UI_VARIANT_NAME = "1";

export const NEW_LANDING_PAGE_UI_VARIANT_NAME = "2";

export const NEW_MYHR_REVAMPED_UI_VERSION = "3";

export const RESTRICTED_CREST_STATUS = "R";

export const ERROR:string ="error";

export const TRUE : string ="true";

export const EMPLOYEE_NAME = "employeeName"

export const SpoofingErrorPageUrl:string ="/myhr/error/403";
/**
 * Country Enum
 */
export const COUNTRY = {
  USA: "USA",
  CAN: "CAN",
  CRI: "CRI"
};

export const MESSAGE_BANNER_CONTENT_FOR_USA_AND_CAN = "<p>If you are having issues adding your Amazon email to your personal device, accessing Chime or EAP, please refer to the FAQs in My HR for the latest information. If you encounter any technical issues, please contact IT at <u><a style='color:#008296' href=\"https://it.amazon.com/\" target=\"_blank\">https://it.amazon.com/</a></u> or via phone at <u><a style='color:#008296' href=\"tel:+12062664357\">+1206-266-4357</a></u></p>";

export const MESSAGE_BANNER_CONTENT_FOR_CRI = "<p>If you are having issues adding your Amazon email to your personal device, accessing Chime or EAP, please refer to the FAQs in My HR for the latest information. If you encounter any technical issues, please contact IT at <u><a style='color:#008296' href=\"https://it.amazon.com/\" target=\"_blank\">https://it.amazon.com/</a></u> or via phone at <u><a style='color:#008296' href=\"tel:+50640372222\">+506-40372222</a></u></p>";

export const ACCESS_DENIED_CODE = '403';

export const MY_HR = "MyHR";
export const LANGUAGE_FALLBACK ={
  METRIC_NAME : "Count",
  METRIC_VALUE: 1,
  METRIC_UNIT: "count",
  SERVICE_NAME: "MyHR",
} ;

export const KHONSU_MFE_FIX_POPSTAR_NAME = "MyHR:KhonsuMfeFix";

export const ATOZ_MYHR_APP_TRANSLATION_NAMESPACE = "atoz_myhr_app";
export const GENERIC_ERROR_PAGE_OPERATION = "LoadGenericErrorPage";
export const GENERIC_COUNTRY_NAME_FOR_LANGUAGE_FALLBACK_BANNER = "your country";
export enum HistoryAction {
  POP = "POP",
  PUSH = "PUSH",
  REPLACE = "REPLACE"
}

export const ENGLISH_CODE = 'en-US';

export const ENTER_KEY_CODE = 13;

export const DEVICE_TYPE = "deviceType";
export const getDynamicValueOfMyHR = (): string => location?.pathname?.split("/")[1];
export const MyHRLiteAttributes = {
  SERVICE_NAME: "AtoZMyHRApp",
  MY_HR_LITE_CLIENT_ID: "myhr-lite-clientId",
  MY_HR_LITE_NAVBAR: "MyHR_Lite_Navbar",
  GET_HELP_PATH: `/${getDynamicValueOfMyHR()}/get-help`,
  LANGUAGE_SELECTION_PATH: `/${getDynamicValueOfMyHR()}/language-selection`,
  LOGOUT_PATH: "/logout?MyHRLiteLogout=true",
  INACTIVE_TIME_OUT: 60, // in seconds
  IDLE_TIMEOUT_BEFORE_PROMPT: 10000, //in milliseconds
  MY_HR_LITE_LANGUAGE_PREFERRED: "myhr-lite-language-preferred",
  MYHR_LITE_PATHNAME: "/myhr-lite",
}

export const TABS = {
  TAB: "tab",
  MY_REQUESTS_TAB: "MY_REQUESTS_TAB"
}

export enum MYHR_LITE_CLIENT_IDS {
  MY_HR_LITE_PHYSICAL = "MyHR_Lite",
  MY_HR_LITE_QR = "MyHR_Lite_QR",
}

export enum deviceTypes {
  KIOSK = "kiosk",
  MOBILE = "mobile",
  DESKTOP = "desktop"
}
